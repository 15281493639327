import Phaser from "phaser";
import Level1Scene from "./level1";
import Level2Scene from "./level2";
import Level3Scene from "./level3";
import Level4Scene from "./level4";
import Level5Scene from "./level5";
import Level6Scene from "./level6";
import Level7Scene from "./level7";
import Level8Scene from "./level8";
import Level9Scene from "./level9";
import Level10Scene from "./level10";

const levels = [
  Level1Scene,
  Level2Scene,
  Level3Scene,
  Level4Scene,
  Level5Scene,
  Level6Scene,
  Level7Scene,
  Level8Scene,
  Level9Scene,
  Level10Scene
];

function launch(containerId: string, scene: number): Phaser.Game {
  return new Phaser.Game({
    type: Phaser.AUTO,
    parent: containerId,
    width: 240,
    height: 240,
    physics: {
      default: "arcade",
      arcade: {
        gravity: { y: 0 },
        debug: false
      }
    },
    scene: [levels[scene]],
    scale: {
      mode: Phaser.Scale.FIT,
      zoom: 2,
      width: 240,
      height: 240
    }
  });
}

export default launch;
export { launch };
