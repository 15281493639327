import store from "@/store";

interface SlimeConfig {
  flipX: boolean;
}

const defaultConfig: SlimeConfig = {
  flipX: true
};

export default class SlimeSprite extends Phaser.Physics.Arcade.Sprite {
  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    frame?: string | number,
    config: SlimeConfig = defaultConfig
  ) {
    super(scene, x, y, texture, frame);
    scene.add.existing(this);
    scene.physics.add.existing(this);

    this.flipX = config.flipX;
    this.setPushable(false);
    this.anims.play("slime-idle");
  }

  kill() {
    this.disableBody(true, true);
    store.commit("game/addKillCount");
  }
}
