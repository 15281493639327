function createZombieAnims(anims: Phaser.Animations.AnimationManager) {
  anims.create({
    key: "zombie-idle",
    frames: anims.generateFrameNumbers("zombie-idle", {
      start: 0,
      end: 3
    }),
    frameRate: 10,
    repeat: -1
  });
}

export { createZombieAnims };
