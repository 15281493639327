function createPlayerAnims(anims: Phaser.Animations.AnimationManager) {
  anims.create({
    key: "hero-run",
    frames: anims.generateFrameNumbers("hero-run", { start: 0, end: 5 }),
    frameRate: 15,
    repeat: -1
  });

  anims.create({
    key: "hero-idle",
    frames: anims.generateFrameNumbers("hero-idle", {
      start: 0,
      end: 5
    }),
    frameRate: 15,
    repeat: -1
  });

  anims.create({
    key: "slash",
    frames: anims.generateFrameNumbers("slash", { start: 0, end: 2 }),
    frameRate: 15,
    repeat: 0
  });
}

export { createPlayerAnims };
