/* eslint-disable @typescript-eslint/no-explicit-any */
import store from "@/store";
import level from "./level9.json";

import PlayerSprite from "@/games/sprites/player.sprite";
import SlimeSprite from "@/games/sprites/slime.sprite";

import { createPlayerAnims } from "@/games/anims/player.anims";
import { createSlimeAnims } from "@/games/anims/slime.anims";
import { createGoblinAnims } from "@/games/anims/goblin.anims";

import SlashSprite from "@/games/sprites/slash.sprite";
import GoblinSprite from "@/games/sprites/goblin.sprite";

export default class LevelZeroScene extends Phaser.Scene {
  tileset!: Phaser.Tilemaps.Tileset;
  wallLayer!: Phaser.Tilemaps.TilemapLayer;
  endPoint!: Phaser.Physics.Arcade.Sprite;
  player!: PlayerSprite;
  slash!: Phaser.Physics.Arcade.Sprite;
  slimeGroup!: Phaser.Physics.Arcade.Group;
  goblinGroup!: Phaser.Physics.Arcade.Group;
  rightRct!: Phaser.GameObjects.Rectangle;
  leftRct!: Phaser.GameObjects.Rectangle;
  topRct!: Phaser.GameObjects.Rectangle;
  bottomRct!: Phaser.GameObjects.Rectangle;

  constructor() {
    super({
      key: "level8"
    });
  }

  preload() {
    this.load.image("tiles", "/assets/dungeon/dungeon_tiles.png");
    this.load.tilemapTiledJSON("dungeon", level);

    this.load.spritesheet(
      "hero-idle",
      "/assets/dungeon/knight_idle_spritesheet.png",
      {
        frameWidth: 16,
        frameHeight: 16
      }
    );
    this.load.spritesheet(
      "hero-run",
      "/assets/dungeon/knight_run_spritesheet.png",
      {
        frameWidth: 16,
        frameHeight: 16
      }
    );
    this.load.spritesheet(
      "slash",
      "/assets/dungeon/slash_effect_anim_spritesheet.png",
      {
        frameWidth: 16,
        frameHeight: 16
      }
    );

    this.load.spritesheet(
      "slime-idle",
      "/assets/dungeon/slime_idle_spritesheet.png",
      {
        frameWidth: 16,
        frameHeight: 16
      }
    );

    this.load.spritesheet(
      "goblin-idle",
      "/assets/dungeon/goblin_idle_spritesheet.png",
      {
        frameWidth: 16,
        frameHeight: 16
      }
    );
    this.load.spritesheet(
      "zombie-idle",
      "/assets/dungeon/big_zombie_idle_anim.png",
      {
        frameWidth: 22,
        frameHeight: 28
      }
    );
  }

  create() {
    const map = this.make.tilemap({ key: "dungeon" });
    const tileset = map.addTilesetImage("Dungeon", "tiles", 16, 16);

    // Create map layers
    map.createLayer("Ground", tileset);
    map.createLayer("Decoration", tileset);

    // Map objects
    const spawnPoint: any = map.findObject("Points", object => {
      return object.name === "Spawn Point";
    });

    // End Point
    this.endPoint = map.createFromObjects("Points", {
      name: "End Point"
    })[0] as Phaser.Physics.Arcade.Sprite;
    this.endPoint.y += 8;
    this.endPoint.setAlpha(0);
    this.physics.add.existing(this.endPoint, true);

    // Slime
    createSlimeAnims(this.anims);
    const slimes: SlimeSprite[] = [];
    const slimeObjects: any[] = map.filterObjects("Points", object => {
      return object.name == "Slime";
    });
    slimeObjects.forEach(object => {
      slimes.push(
        new SlimeSprite(
          this,
          object.x + 8,
          object.y + 8,
          "slime-idle",
          undefined,
          {
            flipX: true
          }
        )
      );
    });
    this.slimeGroup = this.physics.add.group(slimes);

    // Goblin
    createGoblinAnims(this.anims);
    const goblins: GoblinSprite[] = [];
    const goblinObjects: any[] = map.filterObjects("Points", object => {
      return object.name == "Goblin";
    });
    goblinObjects.forEach(object => {
      goblins.push(
        new GoblinSprite(this, object.x + 8, object.y + 8, "goblin-idle")
      );
    });
    goblins.forEach(goblin => {
      goblin.flipX = true;
    });
    this.goblinGroup = this.physics.add.group(goblins);

    // Player
    createPlayerAnims(this.anims);
    this.player = new PlayerSprite(
      this,
      spawnPoint?.x + 8,
      spawnPoint?.y + 8,
      "hero-idle"
    );
    // Player collision detection boxes
    this.createCollisionBox();

    // Slash effect
    this.slash = new SlashSprite(
      this,
      spawnPoint?.x + 20,
      spawnPoint?.y + 8,
      "slash"
    );

    // Wall Layer
    this.wallLayer = map.createLayer("Walls", tileset);
    this.wallLayer.setCollisionByProperty({ collides: true });

    // Events
    this.physics.add.collider(this.player, this.wallLayer, () => {
      store.commit("game/stopAllMovement");
    });

    this.physics.add.collider(this.player, this.slimeGroup, () => {
      store.commit("game/stopAllMovement");
    });

    this.physics.add.collider(this.player, this.goblinGroup, () => {
      store.commit("game/stopAllMovement");
    });

    this.physics.add.overlap(
      this.slash,
      this.slimeGroup,
      (slash: any, slime: any) => {
        slime.kill();
      }
    );

    this.physics.add.overlap(
      this.slash,
      this.goblinGroup,
      (slash: any, goblin: any) => {
        goblin.getAttacked();
      }
    );
  }

  update() {
    if (store.getters["game/hasProgramEnded"]) {
      store.state.game.hasProgramStarted = false;
      return;
    }

    if (
      this.physics.overlap(this.player, this.endPoint) &&
      !store.state.game.hasReachEndPoint
    ) {
      store.state.game.hasReachEndPoint = true;
    }

    this.player.update();
    this.updateCollisionBoxesXY();
    this.checkCollisionOverlap();
    this.slash.update(this.player.currentX, this.player.currentY);
    this.goblinGroup.children.iterate(goblin => {
      goblin.update();
    });
  }

  checkCollisionOverlap() {
    if (!store.state.game.isRunning) {
      return;
    }

    if (this.physics.overlap(this.rightRct, this.slimeGroup)) {
      store.state.game.itemRight = "GREENSLIME";
    } else if (this.physics.overlap(this.rightRct, this.goblinGroup)) {
      store.state.game.itemRight = "GOBLIN";
    } else if (
      this.physics.overlap(
        this.rightRct,
        this.wallLayer,
        undefined,
        (rect, wallTile: any) => {
          return wallTile.properties.collides || false;
        }
      )
    ) {
      store.state.game.itemRight = "WALL";
    } else {
      store.state.game.itemRight = null;
    }

    if (this.physics.overlap(this.leftRct, this.slimeGroup)) {
      store.state.game.itemLeft = "GREENSLIME";
    } else if (this.physics.overlap(this.leftRct, this.goblinGroup)) {
      store.state.game.itemLeft = "GOBLIN";
    } else if (
      this.physics.overlap(
        this.leftRct,
        this.wallLayer,
        undefined,
        (rect, wallTile: any) => {
          return wallTile.properties.collides || false;
        }
      )
    ) {
      store.state.game.itemLeft = "WALL";
    } else {
      store.state.game.itemLeft = null;
    }

    if (this.physics.overlap(this.bottomRct, this.slimeGroup)) {
      store.state.game.itemDown = "GREENSLIME";
    } else if (this.physics.overlap(this.bottomRct, this.goblinGroup)) {
      store.state.game.itemDown = "GOBLIN";
    } else if (
      this.physics.overlap(
        this.bottomRct,
        this.wallLayer,
        undefined,
        (rect, wallTile: any) => {
          return wallTile.properties.collides || false;
        }
      )
    ) {
      store.state.game.itemDown = "WALL";
    } else {
      store.state.game.itemDown = null;
    }

    if (this.physics.overlap(this.topRct, this.slimeGroup)) {
      store.state.game.itemUp = "GREENSLIME";
    } else if (this.physics.overlap(this.topRct, this.goblinGroup)) {
      store.state.game.itemUp = "GOBLIN";
    } else if (
      this.physics.overlap(
        this.topRct,
        this.wallLayer,
        undefined,
        (rect, wallTile: any) => {
          return wallTile.properties.collides || false;
        }
      )
    ) {
      store.state.game.itemUp = "WALL";
    } else {
      store.state.game.itemUp = null;
    }
  }

  createCollisionBox() {
    this.rightRct = this.add.rectangle(
      this.player.prevX + 16,
      this.player.prevY,
      8,
      8,
      500,
      0
    );
    this.physics.add.existing(this.rightRct);

    this.leftRct = this.add.rectangle(
      this.player.prevX - 16,
      this.player.prevY,
      8,
      8,
      500,
      0
    );
    this.physics.add.existing(this.leftRct);

    this.topRct = this.add.rectangle(
      this.player.prevX,
      this.player.prevY - 16,
      8,
      8,
      500,
      0
    );
    this.physics.add.existing(this.topRct);

    this.bottomRct = this.add.rectangle(
      this.player.prevX,
      this.player.prevY + 16,
      8,
      8,
      500,
      0
    );
    this.physics.add.existing(this.bottomRct);
  }

  updateCollisionBoxesXY() {
    this.rightRct.setX(this.player.currentX + 16);
    this.rightRct.setY(this.player.currentY);

    this.leftRct.setX(this.player.currentX - 16);
    this.leftRct.setY(this.player.currentY);

    this.topRct.setX(this.player.currentX);
    this.topRct.setY(this.player.currentY - 16);

    this.bottomRct.setX(this.player.currentX);
    this.bottomRct.setY(this.player.currentY + 16);
  }
}
