import Phaser from "phaser";
import store from "@/store";

export default class PlayerSprite extends Phaser.Physics.Arcade.Sprite {
  prevX: number;
  prevY: number;
  currentX: number;
  currentY: number;
  scene: Phaser.Scene;
  readonly speed: number = 30;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    frame?: string | number
  ) {
    super(scene, x, y, texture, frame);

    scene.add.existing(this);
    scene.physics.add.existing(this);

    this.scene = scene;
    this.prevX = this.body.center.x;
    this.prevY = this.body.center.y;
    this.currentX = this.body.center.x;
    this.currentY = this.body.center.y;

    this.anims.play("hero-idle");
    this.setSize(12, 12);
  }

  preUpdate(time: number, delta: number) {
    super.preUpdate(time, delta);
  }

  update() {
    this.currentX = Math.round(this.body.center.x);
    this.currentY = Math.round(this.body.center.y);
    let nextX = this.currentX;
    let nextY = this.currentY;

    if (store.state.game.isMovingLeft) {
      nextX = Math.round(this.prevX - 16);
      this.flipX = true;
      this.play("hero-run", true);
      this.scene.physics.moveTo(this, nextX, this.prevY, this.speed);
    } else if (store.state.game.isMovingRight) {
      nextX = Math.round(this.prevX + 16);
      this.flipX = false;
      this.play("hero-run", true);
      this.scene.physics.moveTo(this, nextX, this.prevY, this.speed);
    } else if (store.state.game.isMovingDown) {
      nextY = Math.round(this.prevY + 16);
      this.flipX = false;
      this.play("hero-run", true);
      this.scene.physics.moveTo(this, this.prevX, nextY, this.speed);
    } else if (store.state.game.isMovingUp) {
      nextY = Math.round(this.prevY - 16);
      this.flipX = false;
      this.play("hero-run", true);
      this.scene.physics.moveTo(this, this.prevX, nextY, this.speed);
    } else {
      this.play("hero-idle", true);
      this.setVelocity(0, 0);
    }

    if (this.currentX == nextX && this.currentY == nextY) {
      store.state.game.isMovingLeft = false;
      store.state.game.isMovingRight = false;
      store.state.game.isMovingUp = false;
      store.state.game.isMovingDown = false;
      this.prevX = nextX;
      this.prevY = nextY;
    }
  }
}
