import store from "@/store";

export default class SlashSprite extends Phaser.Physics.Arcade.Sprite {
  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    frame?: string | number
  ) {
    super(scene, x, y, texture, frame);
    scene.add.existing(this);
    scene.physics.add.existing(this);
    this.disableBody(false, true);

    this.on(Phaser.Animations.Events.ANIMATION_COMPLETE, () => {
      this.disableBody(false, true);
      store.state.game.isAttackingUp = false;
      store.state.game.isAttackingDown = false;
      store.state.game.isAttackingLeft = false;
      store.state.game.isAttackingRight = false;
      store.state.game.slashCount += 1;
    });
  }

  update(playerX: number, playerY: number) {
    if (store.state.game.isAttackingRight) {
      this.enableBody(true, playerX + 12, playerY, true, true);
      this.angle = 0;
      this.play("slash", true);
    } else if (store.state.game.isAttackingLeft) {
      this.enableBody(true, playerX - 12, playerY, true, true);
      this.angle = 0;
      this.flipX = true;
      this.play("slash", true);
    } else if (store.state.game.isAttackingUp) {
      this.enableBody(true, playerX, playerY - 12, true, true);
      this.angle = -90;
      this.play("slash", true);
    } else if (store.state.game.isAttackingDown) {
      this.enableBody(true, playerX, playerY + 12, true, true);
      this.angle = 90;
      this.play("slash", true);
    }
  }
}
