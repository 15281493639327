function createSlimeAnims(anims: Phaser.Animations.AnimationManager) {
  anims.create({
    key: "slime-idle",
    frames: anims.generateFrameNumbers("slime-idle", {
      start: 0,
      end: 5
    }),
    frameRate: 15,
    repeat: -1
  });
}

export { createSlimeAnims };
