/* eslint-disable @typescript-eslint/no-explicit-any */
import level from "./level2.json";
import { createPlayerAnims } from "@/games/anims/player.anims";
import PlayerSprite from "@/games/sprites/player.sprite";
import store from "@/store";

export default class LevelZeroScene extends Phaser.Scene {
  player!: Phaser.Physics.Arcade.Sprite;
  tileset!: Phaser.Tilemaps.Tileset;
  endPoint!: Phaser.Physics.Arcade.Sprite;

  constructor() {
    super({
      key: "level0"
    });
  }

  preload() {
    this.load.image("tiles", "/assets/dungeon/dungeon_tiles.png");
    this.load.tilemapTiledJSON("dungeon", level);

    this.load.spritesheet(
      "hero-idle",
      "/assets/dungeon/knight_idle_spritesheet.png",
      {
        frameWidth: 16,
        frameHeight: 16
      }
    );
    this.load.spritesheet(
      "hero-run",
      "/assets/dungeon/knight_run_spritesheet.png",
      {
        frameWidth: 16,
        frameHeight: 16
      }
    );
  }

  create() {
    console.log("Create scene called");
    const map = this.make.tilemap({ key: "dungeon" });
    const tileset = map.addTilesetImage("Dungeon", "tiles", 16, 16);

    // Create map layers
    map.createLayer("Ground", tileset);
    map.createLayer("Decoration", tileset);

    // Map objects
    const spawnPoint: any = map.findObject("Points", object => {
      return object.name === "Spawn Point";
    });

    this.endPoint = map.createFromObjects("Points", {
      name: "End Point"
    })[0] as Phaser.Physics.Arcade.Sprite;
    this.endPoint.y += 8;
    this.endPoint.setAlpha(0);
    this.physics.add.existing(this.endPoint, true);

    // Player
    createPlayerAnims(this.anims);
    this.player = new PlayerSprite(
      this,
      spawnPoint?.x + 8,
      spawnPoint?.y + 8,
      "hero-idle"
    );

    // Wall Layer
    const wallLayer = map.createLayer("Walls", tileset);
    wallLayer.setCollisionByProperty({ collides: true });

    console.log("inti:" + this.player.body.center.x);
    this.physics.add.collider(this.player, wallLayer, () => {
      console.log("collide: " + this.player.body.center.x);
      store.commit("game/stopAllMovement");
    });
    this.player.setCollideWorldBounds(true);

    // Set debug
    //enableColliderDebug(this, [wallLayer]);
  }

  update() {
    if (store.state.game.hasProgramStarted && !store.state.game.isRunning) {
      if (
        this.physics.overlap(this.player, this.endPoint) &&
        !store.state.game.hasReachEndPoint
      ) {
        store.commit("game/reachEndPoint");
      }
      store.state.game.hasProgramStarted = false;
    } else {
      this.player.update();
    }
  }
}
