import store from "@/store";

export default class GoblinSprite extends Phaser.Physics.Arcade.Sprite {
  health: number;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    texture: string,
    frame?: string | number
  ) {
    super(scene, x, y, texture, frame);
    scene.add.existing(this);
    scene.physics.add.existing(this);

    this.health = 3;
    this.setPushable(false);
    this.anims.play("goblin-idle");
  }

  getAttacked() {
    this.health--;
    if (this.health == 0) {
      this.kill();
    } else {
      this.disableBody(false, false);
    }
  }

  update() {
    if (!store.getters["game/isAttacking"]) {
      this.body.enable = true;
    }
  }

  kill() {
    store.commit("game/addKillCount");
    this.disableBody(true, true);
  }
}
